<template>
    <div>
        <div v-if="toolbar" class="row mt-4">
            <div class="col-12" :class="{'text-error': isToucedAllTemplateEditor && !isMarkupValid}">
                <v-md-editor 
                    left-toolbar="undo redo | customer_fields"
                    right-toolbar=""
                    v-model="markup" 
                    :toolbar="toolbar"
                    @change="textChange"
                    mode="edit"
                    height="700px">
                </v-md-editor>
                <div v-if="isToucedAllTemplateEditor && !isMarkupValid" class="invalid-feedback">Required field</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'getToolbar', 
            'getTemplate',
            'eventTemplateValidInfoGetter',
            'isToucedAllTemplateEditor'
        ]),
        toolbar() {
            return this.getToolbar;
        },
        markup: {
            get() {
                const val = this.getTemplate['markup'];
                if(val)
                    return val;
                return '';
            }, 
            set(value) {
                this.updateTemplate({ field : 'markup', value });
            }
        },
        isMarkupValid() {
            return this.eventTemplateValidInfoGetter['markup'];
        },
    },
    methods: {
        ...mapMutations(['updateTemplateFields', 'updateTemplate']),
    }
}
</script>
<style lang="scss" scoped>
    .text-error {
        .v-md-editor.v-md-editor--edit {
            border: 1px solid red !important;
        }  
        .invalid-feedback {
            display: block !important;
        }
    }
</style>