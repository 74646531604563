<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container">
            
            <div class="row mt-4 mb-4">
                <div class="col-5">
                    <h2 class="h2">Campaign Creator</h2>
                </div>
                <div class="col-7">
                    <button @click="saveAndGoToCustomersList" class="btn btn-primary float-right">Next</button>
                </div>
            </div>

            <div v-if="otherTemplates.length" class="row mt-4">
                <div class="col-12">
                    <h4 class="h4">Use Previous Template</h4>
                </div>
                <div class="col-12">
                    <select class="custom-select float-left" v-model="parentTemplate">
                        <option :value="null">Not selected</option>
                        <option v-for="(template, index) in otherTemplates" :value="template.id" :key="index">
                            {{ template.subject }} {{template.is_sent ?  "(" + convertBrouserTZDateOnly(template.event_date) + ")" : null }} 
                        </option>
                    </select>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 mb-2">
                    <h4 class="h4">Mail Type</h4>
                </div>
                <div class="col-12">
                    <b-form-radio-group
                        id="event-options"
                        v-model="type"
                        :options="getEventTypeOptions"
                    ></b-form-radio-group>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-2">
                    Email Subject*
                </div>
                <div class="col-10">
                    <b-form-input :class="{ 'is-invalid': isToucedAllTemplateEditor && !isSubjectValid }" v-model="subject"></b-form-input>
                    <div v-if="isToucedAllTemplateEditor && !isSubjectValid" class="invalid-feedback" >Required field</div>
                </div>
            </div>

            <text-template v-if="type == 'text'" />
            <text-file-template v-if="type == 'text-image'" />
            <text-html-template v-if="type == 'html'" />

            <br><br><br><br><br>
        </div>
    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import textTemplate from './EmailTemplates/text';
import textFileTemplate from './EmailTemplates/text-file';
import textHtmlTemplate from './EmailTemplates/html';
import { notificationMixin } from './../../mixins/notification.mixin';
import { timeZone } from './../../mixins/time-zone.mixin';

export default {
    data() {
        return {
            parentTemplateKey: 'parent_template_id',
            typeKey: 'type',
            subjectKey: 'subject'
        };
    },
    mixins:[notificationMixin, timeZone],
    components: {
        navAdmin,
        textTemplate,
        textFileTemplate,
        textHtmlTemplate
    },
    computed: {
        ...mapGetters([
            'getEventTypeOptions',
            'getEvent',
            'eventTemplateValidInfoGetter',
            'isToucedAllTemplateEditor',
            'eventTemplateValidGetter',
            'getTemplate',
            'getAllTemplates'
        ]),
        otherTemplates() {
            const items = this.getAllTemplates.filter(x => x.id != this.getEvent.template_id);
            
            items.sort(function(a,b){
                return new Date(b.event_date) - new Date(a.event_date);
            });
            
            return items;
        },
        parentTemplate: {
            get() {
                return this.getTemplateFieldValue(this.parentTemplateKey);
            }, 
            set(value) {
                this.updateTemplateByParent(value);
                this.updateTemplateFiendValue(this.parentTemplateKey, value);
            }
        },
        type: {
            get() {
                return this.getTemplateFieldValue(this.typeKey);
            },
            set(value) {
                this.updateTemplateFiendValue(this.typeKey, value)
            }
        },
        subject: {
            get() {
                return this.getTemplateFieldValue(this.subjectKey);
            },
            set(value) {
                this.updateTemplateFiendValue(this.subjectKey, value);
            }
        },
        isSubjectValid() {
            return this.eventTemplateValidInfoGetter[this.subjectKey];
        },
    },
    methods: {
        ...mapMutations([
            'touchTemplateEditor',
            'updateTemplate',
            'updateTemplateByParent'
        ]),
        ...mapActions([
            'saveTemplateAction',
            'saveTemplateWithImageAction',
            'getEventAction',
            'saveTemplateHtmlAction',
            'getTemplatesAction'
        ]),
        async saveAndGoToCustomersList() {
            
            this.touchTemplateEditor(true);

            if(this.eventTemplateValidGetter) {
                
                switch(this.type) {
                    case 'text-image':
                        await this.saveTemplateWithImageAction();
                        break;
                    case 'text':
                        await this.saveTemplateAction();
                        break;
                    default:
                        await this.saveTemplateHtmlAction();
                        break;
                }

                this.$router.push({ 
                    name: 'EventCustomers', 
                    params: { 'event_id': this.getEvent.id }
                });
            }
        },
        getTemplateFieldValue(fieldKey) {
            return this.getTemplate[fieldKey];
        },
        updateTemplateFiendValue(field, value) {
            this.updateTemplate({ field, value });
        }
    },
    async mounted() {
        await this.getEventAction(this.$route.params.event_id);
        await this.getTemplatesAction();
    }
}
</script>