<template>
    <div>
        <div class="row mt-4">
            <div class="col-1">Title*</div>
            <div class="col-11">
                <b-form-input :class="{ 'is-invalid': isTouched && !isTitleValid }" v-model="title"></b-form-input>
                <div v-if="isTouched && !isTitleValid" class="invalid-feedback" >Required field</div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-1">SubTitle</div>
            <div class="col-11">
                <b-form-input v-model="subTitle"></b-form-input>
            </div>
        </div>

        <div v-if="toolbar" class="row mt-4">
            <div class="col-1">Text*</div>
            <div class="col-11" :class="{'text-error': isTouched && !isTextValid }">
                <v-md-editor 
                    left-toolbar="undo redo | customer_fields"
                    right-toolbar=""
                    v-model="text" 
                    :toolbar="toolbar"
                    mode="edit"
                    height="200px">
                </v-md-editor>

                <div v-if="isTouched && !isTextValid" class="invalid-feedback">Required field</div>
            </div>
        </div>

        <div v-if="toolbar" class="row mt-4">
            <div class="col-1">Text 2</div>
            <div class="col-11">
                <v-md-editor 
                    left-toolbar="undo redo | customer_fields"
                    right-toolbar=""
                    v-model="text2" 
                    :toolbar="toolbar"
                    mode="edit"
                    height="200px">
                </v-md-editor>
            </div>
        </div>
      
        <div v-if="toolbar" class="row mt-4">
            <div class="col-1">Text 3</div>
            <div class="col-11">
                <v-md-editor 
                    left-toolbar="undo redo | customer_fields"
                    right-toolbar=""
                    v-model="text3" 
                    :toolbar="toolbar"
                    mode="edit"
                    height="200px">
                </v-md-editor>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <h4 class="h4">Call to action button</h4>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1">
                <label class="mt-1">Label:</label>
            </div>
            <div class="col-5">
                <b-form-input v-model="actionText"></b-form-input>
            </div>
            <div class="col-1">
                <label class="mt-1">URL:</label> 
            </div>
            <div class="col-5">
                <b-form-input v-model="actionLink"></b-form-input>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <b-form-checkbox
                    id="is_has_warning"
                    v-model="isHasWarning"
                    name="is_has_warning"
                    :value="1"
                >
                Use warning text
                </b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            titleKey: 'title',
            subTitleKey: 'sub_title',
            textKey: 'text',
            text2Key: 'text2',
            text3Key: 'text3',
            actionTextKey: 'action_text',
            actionLinkKey: 'action_link' ,
            isHasWarningKey: 'is_has_warning',
        }
    },
    computed: {
        ...mapGetters([
            'getToolbar',
            'eventTemplateValidInfoGetter',
            'isToucedAllTemplateEditor',
            'getTemplate'
        ]),

        toolbar() {
            return this.getToolbar;
        },

        isTouched() {
            return this.isToucedAllTemplateEditor;
        },

        title: {
            get() {
                return this.getTemplateFieldValue(this.titleKey);
            },
            set(value) {
                this.updateTemplateFieldValue(this.titleKey, value);
            }
        },
        isTitleValid() {
            return this.eventTemplateValidInfoGetter[this.titleKey];
        },

        subTitle: {
            get() {
                return this.getTemplateFieldValue(this.subTitleKey);
            },
            set(value) {
                this.updateTemplateFieldValue(this.subTitleKey, value);
            }
        },

        text: {
            get() {
                return this.getTemplateFieldValue(this.textKey);
            },
            set(value) {
                this.updateTemplateFieldValue(this.textKey, value);
            }
        },
        isTextValid() {
            return this.eventTemplateValidInfoGetter[this.textKey];
        },

        text2: {
            get() {
                return this.getTemplateFieldValue(this.text2Key);
            },
            set(value) {
                this.updateTemplateFieldValue(this.text2Key, value);
            }
        },

        text3: {
            get() {
                return this.getTemplateFieldValue(this.text3Key);
            },
            set(value) {
                this.updateTemplateFieldValue(this.text3Key, value);
            }
        },

        actionText: {
            get() {
                return this.getTemplateFieldValue(this.actionTextKey);
            },
            set(value) {
                this.updateTemplateFieldValue(this.actionTextKey, value);
            }
        },

        actionLink: {
            get() {
                return this.getTemplateFieldValue(this.actionLinkKey);
            },
            set(value) {
                this.updateTemplateFieldValue(this.actionLinkKey, value);
            }
        },
        isHasWarning: {
            get() {
                return this.getTemplateFieldValue(this.isHasWarningKey);
            },
            set(value) {
                this.updateTemplateFieldValue(this.isHasWarningKey, value);
            }
        }
    },
    methods: {
        ...mapMutations(['updateTemplateFields', 'updateTemplate']),

        getTemplateFieldValue(fieldKey) {
            const val = this.getTemplate[fieldKey];
            if(val)
                return val;
            return '';
        },
        
        updateTemplateFieldValue(field, value) {
            this.updateTemplate({ field, value });
        }
    }
}
</script>
<style lang="scss" scoped>
    .text-error {
        .v-md-editor.v-md-editor--edit {
            border: 1px solid red !important;
        }  
        .invalid-feedback {
            display: block !important;
        }
    }
</style>